import React, { createContext, useReducer, useContext } from "react"
export const AuthContext = createContext()
export const useStore = () => useContext(AuthContext)

export const GlobalAuthProvider = ({ children, initialState, reducer }) => {
  let [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  )
}
