const cartExampleData = {
  "id": "a2f48969-9c07-4efb-a358-9cbf58d3e56b",
  "itemAdded": {
    "id": "1dfe6402-f5a2-4bbc-87ac-6b1c86cce28f",
    "sku": "SKP-GO",
    "quantity": 1,
    "price": {
      "currencyCode": "USD",
      "amount": 119
    },
    "totalPrice": {
      "currencyCode": "USD",
      "amount": 119
    },
    "name": "Product name",
    "image": "https://picsum.photos/115/115",
  },
  "lineItems": [
    {
      "id": "1dfe6402-f5a2-4bbc-87ac-6b1c86cce28f",
      "sku": "SKP-GO",
      "quantity": 1,
      "price": {
        "currencyCode": "USD",
        "amount": 119
      },
      "totalPrice": {
        "currencyCode": "USD",
        "amount": 119
      },
      "name": "Product name",
      "image": "https://picsum.photos/115/115",
    }
  ],
  "totalPrice": {
    "currencyCode": "USD",
    "amount": 119
  },
  "totalLineItemQuantity": 1,
  "relatedItems": [
    {
      "id": "1dfe6402-f5a2-4bbc-87ac-6b1c86cce28f",
      "sku": "SKP-GO",
      "price": {
        "currencyCode": "USD",
        "amount": 119
      },
      "totalPrice": {
        "currencyCode": "USD",
        "amount": 119
      },
      "name": "Product name",
      "image": "https://picsum.photos/115/115",
      "link": {
        "url": "https://www.trimble.com",
        "text": "Learn More",
      },
    }    
  ]
}

export const initialState = {
  isOneTrustInitialized: false,
  locale: "en",
  cart: null,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ONE_TRUST_INITIALIZED":
      return {
        ...state,
        isOneTrustInitialized: true,
      }      
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload.locale,
      }
    case "UPDATE_CART":
      return {
        ...state,
        cart: action.payload,
      }      
    default:
      return state
  }
}
